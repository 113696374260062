
.search_container{
    max-width: 500px;
    border-radius: 4px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    height: 40px;
    display: flex;
}

.search_container input,
.search_container select{
    height: 100%;
    width: 100%;
    padding: 0 10px;
    border: none;
    outline: none;
    appearance: none;
}
.search_container span{
    background-color: #3491E8;
    padding: 2px 15px;
    border-radius: 4px;
    margin: 4px;
}
.standard_in{
    max-width: 1000px;
    width: 100%;
    margin: auto;
}
.standard_in .smd{
    color: #3491E8;
    font-size: 15px;
    font-weight: 700;
}
.ml_01{
    margin-left: 5px;
}
.standard_in .smx{
    padding: 0 20px;
    font-size: 15px;
    font-weight: 700;
}
.myCaurosel{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 10px;
}
.inside_id{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.inside_id img{
    border-radius: 10px;
}
.botBorder img{
    max-height: 200px;
    width: 100%;
    border-radius: 10px;
}
.inside_id2{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.thres img{
    max-height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
}
.imgOnes{
    border-radius: 10px 0 0 0;
    margin-bottom: 0.5px;
}
.imgtwos{
    border-radius: 0 0 0 10px;
    margin-top: 0.5px;
}
.imgThres{
    border-radius: 0 10px 10px 0;
    height: inherit;
}

.LastColImg img{
    height: inherit;
    /* height: 100%; */
    border-radius: 10px;
}
.pl_011{
    padding-left: 3px;
}
.join_now_btn{
    border-radius: 10px;
    background-color: rgb(207,12,54,0.2);
    padding: 4px;
    height: 44px;
    max-width: 200px;
    display: flex;
    width: 100%;
}
.join_now_btn span{
    color: #cf0c36;
    font-weight: 500;
    text-align: center;
    width: 100%;
    padding-top: 5.5px;
}
.join_now_btn samp{
    font-weight: 500;
    text-align: center;
    width: 100%;
    background: linear-gradient(to right, rgb(207,12,54,0.8), rgb(207,12,54));
    height: 100%;
    padding-top: 5.5px;
    width: 44px;
    border-radius: 9px;
}
.about{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
}
.about_pix_container{
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: 10px;
}
.aboutPix1 img{
    /* max-height: 450px;
    height: 100%; */
    border-radius: 10px;
    width: 100%;
    margin-left: auto;
}

.aboutPix2 img{
    max-height: 210px;
    border-radius: 10px;
    margin-left: auto;
    margin-top: 10px;
    max-width: 200px;
}
.aboutPix3 img{
    border-radius: 10px;
    max-width: 250px;
}
.aboutPix4 img{
    border-radius: 10px;
    max-height: 350px;
}
.padAbtpix{
    padding-top: 120px;
}
.bg_ashy{
    background-color: #f1f1f1;
}
.reviews{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}

.taking{
    max-width: 700px;
    width: 100%;
    margin: auto;
}
.reviewContent{
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 10px;
    position: relative;
}
.profileReview{
    height: 70px;
    width: 70px;
    border-radius: 100%;
    display: block;
    position: absolute;
    bottom: -30px;
    right: 50px;
    border: 1px solid #d5d2d2;
    padding: 5px;
}
.logoIndustries{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.logoIndustries img{
    max-height: 100px;
}
.newsletterContainer{
    max-width: 800px;
    background-color: #fff;
    padding: 50px 60px;
    margin: 20px auto 50px auto;
    border-radius: 5px;
}
.newsLetterText{
    font-size: 22px;
    color: #cf0c36;
}
.newLetterInpuContainer{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    margin-top: 10px;
    display: flex;
    padding: 2.5px;
    height: 45px;
}
.newLetterInpuContainer input{
    width: 100%;
    border: none;
    padding: 0 10px;
}
.newLetterInpuContainer button{
    border-radius: 9px;
    height: 100%;
    max-width: 150px;
    background-color: #cf0c36;
    color: #fff;
    font-weight: 500;
    width: 100%;
    display: inline-block;
    text-align: center;
    border: none;
    text-decoration: none;
    outline: none;
    padding: 0;
}

.carodisplay{
    display: block;
}
.carodisplay2{
    display: none;
}
.cauroselRefReview{
    display: none;
}
.flexImgs{
    display: flex;
    align-items: center;
}

.newsHeaderImg img{
    width: 100px !important;
    max-height: 450px;

}
.newsHeaderImg{
    display: flex;
    padding: 0 20px;
}
.newsHeaderImg img{
    max-height: 80px;
}
@media (max-width:900px) {
    .newsHeaderImg{
        flex-wrap: wrap;
        flex-direction: column;
        padding: 20px;
    }
    .newsHeaderImg .center2{
        margin: initial;
    }
}
@media (max-width:768px) {
    .reviewContent .font_12,.reviewContent .font_14{
        font-size: 11px !important;
    }
}
@media (max-width:718px) {
    .reviews{
        display: none;
    }
    .cauroselRefReview{
        display: block;
        
    }
    .reveiwCarouselContent{
        padding-bottom: 100px;
        min-height: 500px;
    }
    
}
@media (max-width:600px) {
    .font_36{
        font-size: 26px;
    }
    
}
@media (max-width:500px) {
    .standard_in .smd{
        font-size: 11px;
        font-weight: 600;
    }
    .reveiwCarouselContent{
        min-height: 620px;
    }
    .standard_in .smx{
        padding: 0 5px;
        font-size: 11px;
        font-weight: 600;
    }
    .carodisplay{
        display: none;
    }
    .carodisplay2{
        display: block;
    }
    .myCaurosel{
        grid-template-columns: 4fr 2fr;
        grid-gap: 10px;
    }

    .join_now_btn{
        height: 38px;
        max-width: 150px;
    }
    .join_now_btn span{
        padding-top: 3.5px;
        font-size: 14px;
    }
    .join_now_btn samp{
        padding-top: 3.5px;
        width: 38px;
    }
    .aboutPix3 img{
        max-width: 100%;
    }
    .newsLetterText{
        font-size: 19px;
    }
    .newLetterInpuContainer{
        padding: 2px;
        height: 40px;
    }
    
    .newLetterInpuContainer button{
        max-width: 100px;
        font-size: 12px;
    }
    .newLetterInpuContainer input{
        font-size: 12px;
    }
    .newsletterContainer{
        padding: 40px 20px;
    }
    
}

@media (max-width:450px) {
    .blue.font_34,.red.font_34{
        font-size: 24px;
    }
    .about_pix_container{
        grid-template-columns: 1fr;
    }
    .aboutPix2 img{
        max-height: 100%;
        /* margin-left: auto; */
        margin-top: 10px;
        max-width: 100%;
    }
    .pt_6.aboutPix3{
        padding-top: 0;
    }

}

@media (max-width:350px) {
    .newsletterContainer{
        padding: 40px 15px;
    }
}





















