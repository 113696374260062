.aboutLander2{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, #fff, #FAD4C1);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding: 35px;
}
.selectedForm{
    border: none !important;
    min-width: 130px;
    outline: none;
}
.colorDisplayer{
    display: inline-block;
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 20px;
}
.vote_receipt_container{
    max-width: 300px;
    display: flex;
    border: 1px solid #f1f1f1;
    /* padding-left: ; */
    padding: 3px 3px 3px 10px ;
}
.vote_receipt_container input{
    border: none !important;
}
.receipt_content{
    max-width: 820px;
    width: 100%;
    padding: 30px;
    background-color: #FFFFFF;
    margin: 0 auto;
}
.receipt_header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
.receipt_header small{
    font-weight: 600;
}
.receipt_services_header{
    max-width: 200px;
    font-weight:700;
    font-size: 32px;
    line-height: 1.3;
    text-align: right;
    color: #004188;
}
.receipt_color{
    color: #004188;
}
.fonted_600{
    font-weight: 600;
}
.receipt_date_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 15px;
}
.receipt_banner{
    padding: 7px 10px;
    background-color: #004188;
    color: #FFFFFF;
    
}
.text_align_right{
    text-align: right;
}
.receipt_banner_gridded{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
}
.z-10{
    z-index: 10;
}
.receipt_smallHeader{
    font-size: 10px;
    font-weight: 800;
    color: #004188;
}



.optspan{
    width: 250px;
    display: inline-block;
}
.rotate{
    transform: rotate(180deg);
    transition: transform .5s;
}
.border_radius{
    border-radius: 10px;
}
.FlexModal{
    display: flex;
    flex-wrap: wrap;
}
.border_filter{
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.borderRight{
    border-right: 1px solid #f1f1f1;
}
.borderRight:hover{
    background-color: #f1f1f1;
    cursor: pointer;
}
.shop{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 40px;
}
.shop_item{
    border-radius: 10px;
    background-color: #fff;
    padding: 4px;
}
.shop_description{
    background-color: rgb(189,18,61,.1);
    border-radius: 10px;
    padding: 20px
}
.add_to_cart{
    background-color: rgb(189,18,61,.7);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    color: #fff;
    align-items: center;
    font-size: 24px;
    position: relative;
}
.add_to_cart div{
    position: absolute;
    top: -5px;
    left: 7px;
}

.townHall div:first-child{
    background-color: blue;
    color: blue;
}
.different.from.balaBlue{
    color: #3491E8;
}
.clothes .shop_description{
    background-color: rgb(52,145,232,.1);
}

.clothes .add_to_cart{
    background-color: rgb(52,145,232,.7);
}
.clothes .red{
    color: #3491E8;
}
.blue{
    color: #3491E8;
}

.haefa .carousel-indicators button{
    width: 5px !important;
    border-radius: 100%;
}


.homeBased .carousel-indicators button,
.reviews .carousel-indicators button{
    display: none !important;
}
.homeBased .carousel-control-prev,
.homeBased .carousel-control-next {
    background-color: rgb(207,12,54,0.5) ;
    height: 50px;
    width: 50px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
}
.haefa .carousel-control-prev-icon,
.carousel-control-next-icon{
    /* display: none !important; */
}


.homeBased .carousel-control-prev-icon,
.carousel-control-next-icon{
    /* display: none !important; */
}

.aboutLander2 img{
    max-height: 406px;
}
.aboutLander2.xdf{
    background: linear-gradient(to right,#fff, #fff);
}
.classLanding_page{
    background: linear-gradient(to right, rgb(52,145,232,.09), #fff);
    width: 100%;
    min-height: 400px;
    
    padding: 35px;
}
.classDivider{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    max-width: 900px;
    margin: 0 auto;
}
.ClassHeader{
    font-size: 40px;
    font-weight: 200;
    line-height: 1.2;
}
.classesDescHeader{
    line-height: 1.2;
    font-size: 35px;
    font-weight: 800;
}
.diveid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    position: absolute;
    bottom: -120px;
    padding: 20px;
}
.text_align_center{
    padding-top: 40px;
}
.diveid img{
    max-width: 180px;
    border-radius: 4px;
}
.model_class_CONtainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin-top: 40px;
}
.mageHeader{
    background-color: #3491E8;
    border-radius: 10px;
    padding: 20px;
    display: flex;
}
.upcoming_mag_Btn2{
    color: #fff;
    background-color: rgb(207,12,54,.9);
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    padding: 8px 20px;
}
.upcoming_mag_Btn2:hover{
    color: #fff;
}
.upcoming_mag_Btn_iii{
    color: #fff;
    background-color: #CF5271;
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 20px;
    width: 100%;
    padding: 8px 20px;
}
.magazin_covers{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 35px;
    margin-top: 40px;
}

.mag{
    border-radius: 10px;
}
.mag img{
    border-radius: 10px 10px 0 0;
}
.aboutLander2fx{
    width: 100%;
    min-height: 500px;
    align-items: center;
    /* background: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)),url(../Css/Images/andrea-mininni-VLlkOJdzLG0-unsplash.jpg); */
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    padding: 35px;
    padding-top: 70px;
}
.error {
    /* position: absolute; */
    position: initial;
    left: initial;
    top: initial;
    transform: initial;
    font-family: inherit;
    /* color: #363e49; */
}
.aboutLander2fx.x_2{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)),url(../Css/Images/halil-ibrahim-cetinkaya-lbBrOujiO-Q-unsplash.jpg);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    padding: 35px;
    padding-top: 70px;
}
.aboutLander2fx.x_3{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)),url(../Css/Images/md-duran-rE9vgD_TXgM-unsplash.jpg);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    padding: 35px;
    padding-top: 70px;
}
.eventGridContainer{
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}
.evntCont{
    border-radius: 10px;
}
.evntCont img{
    border-radius: 10px 10px 0 0 ;
}
.upcoming_Eevent_btn{
    display: block;
    text-align: center;
    padding: 8px 20px;
    color: #fff;
    font-size: 14px;
    background-color: #3491E8;
    border-radius: 7px;
    border: none;
    width: 100%;
}
.upcoming_Eevent_btn:hover{
    color: #fff;
}
.eventImgContainer img{
    max-height: 500px;
    /* width: 100%; */
    display: inline-block;
    margin: 0 auto;
    border-radius: 10px;
}
.eventImgContainer{
    display: flex;
    /* align-items: center; */
}
.eventIckets_btn{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    padding: 8px 20px;
    max-width: 180px;
    text-align: center;
    display: block;
    margin-top: 10px;
}
.standardEvent{
    max-width: 800px;
    width: 100%;
}
.loaderBg{
    background-color: #f1f1f1;
    min-height: 300px;
    padding-top: 30px;
    padding-left: 30px;
}
.minWidthCarosel{
    max-width: 800px;
    margin: auto;
}
.w_1000{
    width: 500px;
}
.mageHeaderss{
    background-color: #CF5271;
    border-radius: 10px;
    padding: 20px;
    display: flex;
}
.modelling{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.first_side{
    background-color: rgb(52,145,232,.1);
    height: 90vh;
    display: flex;
}
.first_sideContent{
    max-width: 450px;
    margin: 12vh 0 0 auto;
    height: 425px;
    background-color: #fff;
    width: 100%;
    position: relative;
    padding: 50px;
}
.first_side2{
    height: 90vh;
    display: flex;
    background-color: rgb(207,12,54,.08);
}
.second_sideContent{
    max-width: 450px;
    margin: 12vh auto 0 0;
    height: 425px;
    width: 100%;
    position: relative;
}
.second_sideContent img{
    width: 100%;
    height: 100%;
}
.ModelsHeaderCont_ents{
    font-size: 50px;
    font-weight: 800;
    line-height: 1;
    margin-top: 15%;
    color: black;
}
.modeling_BTN{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: rgb(52,145,232,.9);
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 175px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.modeling_BTN2{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #3491E8;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 40px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.modeling_BTN:hover{
    color: #ffffff;
}


.modeling_BTN3{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: rgb(207,12,54,.9);
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 175px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.modeling_BTN5{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #CF0C36;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 40px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.cart_container{
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-gap: 20px;
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
}
.cart_body{
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 0 30px 0;
}
.cart_body2{
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 0 0 0;
}
.cart_divider{
    border-bottom: 1px solid #eceefc;
    padding: 15px;
}
.cart_dividers{
    display: grid;
    grid-template-columns: 1fr 4fr;
}
.cart_dividersheck{
    display: grid;
    grid-template-columns: 2fr 5fr;
}
.cart_headerTit{
    font-size: 19px;
    font-weight: 700;
    padding: 0 15px 5px 15px;
    border-bottom: 2px solid #eceefc;
}
.xs .cart_divider button{
    border-radius: 5px;
    background-color: #3491E8;
    padding: 0 5px;
    color: #fff;
    width: 25px;
    display: block;
    text-align: center;
    border: none;
}
.cart_dividers img,.cart_dividersheck img{
    border-radius: 5px;
    max-height: 170px;
    color: #eceefc;
}
.cart_divider input{
    max-width: 500px;
    width: 100%;
    border: 2px solid #ECEEFC;
    padding: 8px 10px;
    border-radius: 7px;
}
.cart_divider input.error{
    border: 2px solid #CF5271;
}
.submitHandlerBtn{
    background-color: #3491E8;
    color: #fff;
    padding: 7px 20px;
    max-width: 150px;
    text-align: center;
    display: inline-block;
    width: 100%;
    border: none;
    border-radius: 5px;
}
.ItemsDetail{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 40px;
}
.divItemsOption{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 30px 0;
}
.divItemsOption div{
    padding: 10px;
}
.divItemsOption img{
    border-radius: 4px;
}
.divItemsOption img:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.detailRightN{
    padding-left: 100px;
}
.detailCartAdd{
    border-radius: 40px;
    display: flex;
    background-color: #f1f1f1;
    max-width: 300px;
}
.detailCartAdd span{
    font-size: 26px;
    padding: 8px 30px 14px 30px;
    display: inline-block;
}
.detailCartAdd samp{
    font-size: 22px;
    padding: 14px 10px 6px 10px;
    display: inline-block;
}
.AddToCartDetailsBtn{
    padding: 13px 20px;
    border: none;
    border-radius: 30px;
    max-width: 180px;
    width: 100%;
    text-align: center;
    background-color: #3491E8;
    color: #fff;
}


.loginFormBg{
    background-color: #EFF0F4;
    height: 94vh;
    padding: 0 10px;
}
.loginFormBg2{
    background-color: #EFF0F4;
    padding: 0 20px;
}

.loginContainerBox3{
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    margin: auto;
    padding: 40px;
    border-radius: 7px;
}
.paddForm{
    padding: 90px 0;
}
.loginContainerBox3 input,
.registerContent input{
    margin: 15px 0;
    padding: 9px 10px;
    display: block;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #ccc7c7;
}
.loginContainerBox3 input.xb,
.registerContent input.xb{
    border: none;
    margin: 0;

}
.registerContent .signUp_address,
.registerContent .signUp_address2{
    margin: 15px 0;
}
.registerContent .signUp_address .xcv,
.registerContent .signUp_address2 .xcv{
    border-radius: 4px 0 0 4px;
}
.registerContent .signUp_address input,
.registerContent .signUp_address2 input{
    margin: 0;
}
.registerContent .signUp_address .xcv2,
.registerContent .signUp_address2 .xcv2{
    border-radius: 0 4px 4px 0 ;
}
.signUp_address{
    display: grid;
    grid-template-columns: 5fr 1fr;
}
.signUp_address2{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.passwordFormContainer{
    display: flex;
    margin: 15px 0;
    /* padding: 9px 10px; */
    border-radius: 4px;
    border: 1px solid #ccc7c7;
}
.sight{
    padding-top: 9px;
    padding-right: 5px;
}
.loginContainerBox3 button{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 9px;
}
.signUpFormContainer button{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    max-width: 200px;
    border: none;
    padding: 9px;
    width: 100%;
}
.signUpFormContainer{
    margin: 10px auto;
    max-width: 1200px;
    background-color: #fff;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 5px;
}

.signUpForms{
    padding: 0 100px 60px 60px;
}
.classics img{
    border-radius: 5px;
    /* height: 100%; */
}
.borrad{
    border: 0.5px solid #f1f1f1;
}
.myMensory img{
    padding: 5px;
    border-radius: 5px;
}
.kidsProfile img{
    border-radius: 10px;
}
.kidsProfile{
    margin: 5px;
}
.center_imager{
    display: block;
    
    object-fit: cover;
    object-position: center;
    max-width: 100%;
}
.get_featured_mag_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
    column-gap: 40px;
    padding-top: 40px;
}
.modalHeader{
    border: none;
}
.get_featured_mag_container img{
    max-width: 100%;
}
/* .modal-content {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 18px;
    height: 533px;
} */

.mdalcontent{
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 18px;
    height: 533px;
}
.fade.modal.show{
    padding: 0;
}
.modalImgContainer{
    display: flex;
    border-radius: 10px 0 0 10px;
    align-items: center;
    background: rgb(255,255,255,.5);
}
.cancelImg{
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 100;
}
.modalImgContainer img{
    width: 100%;
    border-radius: 10px 0 0 10px;
}
.modalProfileInfo{
    background-color: #fff;
    border-radius: 0 10px 10px 0;
}
.profContentt{
    border-radius: 12px;
    background-color: #f1f1f1;
    margin: 5px;
    
}
.padPf{
    padding: 10px 20px;
}
.appearanceContainer{
    padding: 20px;
}
.div2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 15px;
}
.socialMdea{
    display: flex;
    max-width: 230px;
    justify-content: space-between;
}
.shop_item img{
    height: 260px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 4px;
}
.myToast{
    position: absolute;
    z-index: 1000000000000;
    background-color: #3491E8;
}
.maxWidth{
    max-width: 200px;
    margin: auto;
    
    
}
.maxWidth .fade.toast{
    background-color: transparent !important;
    border: none;
    box-shadow: none;
}
.Toaster{
    background-color: rgb(25,135,84,.5);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
}
.pt{
    padding-top: 2px;
}
input.xdForm,
.xdForm.passwordFormContainer{
    border: 1px solid #CF0C36 !important;
}
input::placeholder {
    color: #757680;
}
.absoluteAdd{
    position: absolute;
    right: 1px;
    font-size: 25px;
    top: -7px;
}
.pr_25{
    padding-right: 25px;
}
.cencelBtnIcon{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
input.errr{
    border: 1.5 solid #DC3545 !important;
}
.content_Tickets_container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(25rem, 100%), 1fr));
    row-gap: 30px;
    column-gap: 30px;
    background: transparent;
}
.tickConatainer{
    background-color: #fff;
}
.tickConatainer .ticketImgBg{
    max-height: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
}
.tickets_detailerContainer{
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100%;
    position: relative;
    z-index: 10;
}
.qrcodeImg img{
    max-width: 120px;
}
.text_TickestInfo{
    border-right: 2px dashed black;
    padding: 15px;
}
.extraTicket{
    min-height: 100px;
}
.form-row.pt_2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}
.ImgMagrequest{
    text-align: center;
}
.emptyImg{
    max-width: 500px;
}
.emptyImg img{
    max-width: 100%;
}
.shop .shop_item img{
    max-width: 100%;
}
.disp-Grid{
    display: grid;
}
.terms_of_use_container{
    position: relative;
    padding-top: 40px;
}
.red_bg_terms{
    background-color: #CF0C36;
    position: absolute;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100%;
}
.terms_of_use_content{
    max-width: 80%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 10;
    background-color: #fff;
    padding: 40px 30px;
}
.ckecb0x span, .ckecb0x input{
    margin: auto 0;
}
@media (max-width:900px) {
    .shop{
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .shop .shop_item img{
        height: 240px;
        display: block;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
    }
    .magazin_covers{
        grid-gap: 20px;
    }
    .diveid img{
        max-width: 100%;
        border-radius: 4px;
    }
    .signUpForms{
        padding: 20px 60px 20px 20px;
    }
    .detailRightN{
        padding-left: 20px;
    }
}
@media (max-width:860px) {
    .diveid{
        bottom: -80px;
        padding: 20px;
    }
    .eventGridContainer{
        grid-gap: 20px;
    }
    
    .eventGridContainer .p-3{
        padding: 12px !important;
    }
    .cart_container{
        grid-template-columns: 3fr 2fr;
    }
    .get_featured_mag_container{
        grid-template-columns: 1fr;
        
    }
    
}
@media (max-width:800px) {
    .signUpForms{
        padding: 20px 40px 20px 0;
    }
}
@media (max-width:700px) {
    .signUpFormContainer{
        padding: 15px;
        grid-template-columns: 1fr;
        max-width: 400px;
        margin: auto;
    }
    .ItemsDetail{
        grid-template-columns: 1fr ;
    }
    .detailRightN{
        padding-left: 0;
        padding-top: 30px;
    }
    .classics{
        display: none;
    }
    .loginFormBg2{
        padding: 0 10px;
    }
    .signUpForms{
        padding: 20px 0;
        
    }
}
@media (max-width:650px) {
    .eventGridContainer{
        grid-template-columns: 1fr 1fr;
    }
    .modelling{
        grid-template-columns: 1fr;
    }
    .second_side{
        display: none;
    }
    .first_sideContent{
        max-width: 100%;
        margin: 30px 0 0 0;
        
    }
    .ClassHeader{
        font-size: 28px;
    }
    .classesDescHeader{
        font-size: 23px;
    }
    .classDivider{
        grid-template-columns: 1fr;
    }
    
    .diveid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        position: static;
        margin-bottom: -200px;
        padding: 0;
    }
    .font_40{
        font-size: 20px;
    }
    .aboutLander2 .font_20{
        font-size: 14px;
    }
    .shop{
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    .shop .shop_item img{
        height: 150px;
        display: block;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
    }
    .magazin_covers{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .magazin_covers .p-3{
        padding: 10px !important;
    }
    .cart_container{
        grid-template-columns: 1fr;
        padding: 0 10px;
    }

}

@media (max-width:600px) {
    .homeBased .carousel-control-prev,
    .homeBased .carousel-control-next {
        height: 30px;
        width: 30px;
        border-radius: 25px;
    }
    .haefa .carousel-control-prev-icon,
    .carousel-control-next-icon{
        width: 1rem;     
    }


    .receipts *{
        font-size: 12px;
    }
    .receipt_services_header{
        font-size: 18px;
    }
    .receipt_header{
        grid-template-columns: 1fr;
    }
    .receipts .logoContentContaIner{
        position: absolute;
        top: 0;
        right: 0;
    }
    .left_auto.receipt_services_header{
        margin: 0;
    }
    .homeBased .carousel-control-prev-icon,
    .carousel-control-next-icon{
        width: 1rem; 
    }
    .model_class_CONtainer{
        grid-template-columns: 1fr;
    }
    .font_32{
        font-size: 25px;
    }
    .standard_width.xr{
        padding-top: 50px;
    }
    .flex.xbfd{
        display: block;
    }
    .xbfd .ml_2{
        margin: 35px 0 0 0;
    }
    /* .modal-content {
        grid-template-columns: 1fr;
        height: inherit;
    } */
    .mdalcontent{
        grid-template-columns: 1fr;
        height: inherit;
    }
    .modalImgContainer img{
        border-radius: 10px 10px 0 0;
    }
    .modalProfileInfo{
        background-color: #fff;
        border-radius: 0 0 10px 10px;
    }
    .modalImgContainer{
        border-radius: 10px 10px 0 0;
    }
}
@media (max-width:550px) {
    .paddForm{
        padding: 40px 0;
    }
    .loginContainerBox3{
        padding: 25px;
    }
    .ModelsHeaderCont_ents{
        font-size: 26px;
    }
    .aboutLander2{
        height: 480px;
        grid-template-columns: 1fr;
        padding: 35px;
        grid-gap: 0;
    }
    .aboutLander2 img{
        height: 300px;
        display: block;
    
        object-fit: cover;
        object-position: center;
        max-width: 100%;
    }
    .font_12{
        font-size: 11px;
    }
    .font_15{
        font-size: 12px;
    }
    .mageHeader .font_20{
        font-size: 15px;
    }
    .upcoming_mag_Btn2{
        max-width: 100px;
        padding: 7px 10px;
        font-size: 12px;
    }
}

@media (max-width:500px) {
    .cart_dividers .font_18{
        font-size: 13px;
    }
    .cart_dividers .font_14{
        font-size: 11px;
    }
    .cart_dividers .font_20{
        font-size: 14px;
    }
    .cart_dividers .mt_1{
        margin-top: 2px;
    }
    .cart_divider .pt_2{
        padding-top: 10px;
    }
    .cart_divider .text-danger{
        font-size: 12px;
    }
    .font_24{
        font-size: 18px;
    }
    .border_filter .borderRight{
        font-size: 11px;
    }
    .border_filter .borderRight.py_2{
        padding: 15px 0;
    }
    .border_filter{
        margin-top: 14px;
    }
    .shop{
        grid-gap: 10px;
    }
    .shop_description .font_16{
        font-size: 12px;
    }
    .shop_description .font_12{
        font-size: 11px;
    }
    .shop_description{
        padding: 10px
    }
    .magazin_covers{
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }
    .p-4.mainsRecpt{
        padding: 20px 0 !important;
    }
    .receipt_content{
        padding: 30px 0;
    }
}
@media (max-width:450px) {
    .terms_of_use_content{
        max-width: 95%;
        padding: 25px 15px;
    }
    .terms_of_use_container .pb_4{
        padding-bottom: 20px;
    }
    .font_16{
        font-size: 13px;
    }
    .detailCartAdd{
        max-width: 150px;
    }
    .detailCartAdd span{
        font-size: 26px;
        padding: 8px 20px 10px 20px;
    }
    .detailCartAdd samp{
        font-size: 17px;
        padding: 19px 10px 6px 10px;
    }
    .pl_2.msw{
        padding-top: 10px;
    }
    .AddToCartDetailsBtn{
        padding: 10px 20px;
        max-width: 150px;
    }
    
    .font_28{
        font-size: 22px;
    }
    .aboutLander2fx .mt_7{
        margin-top: 30px;
    }
    .mageHeader .font_20{
        font-size: 12px;
    }
    .eventGridContainer{
        grid-template-columns: 1fr;
    }
    .upcoming_mag_Btn2{
        max-width: 80px;
        padding: 6px 10px;
        font-size: 11px;
    }
    .diveid{
        margin-bottom: -160px;
        padding: 0;
    }
    .classLanding_page{
        padding: 20px;
    }
    .standard_width.xr{
        padding-top: 80px;
    }
}
@media (max-width:400px) {
    .aboutLander2{
        padding: 15px;
    }
    .font_20{
        font-size: 16px;
    }
    .border_filter .borderRight{
        font-size: 10px;
    }
    .border_filter .borderRight.py_2{
        padding: 10px 0;
    }
    .upcoming_mag_Btn2{
        min-width: 80px;
        padding: 6px;
        font-size: 10.5px;
    }
}