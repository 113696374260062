*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  scroll-behavior: smooth;
  word-wrap: break-word; 
  color: #2E343B;
  
}
/* .justify_me *{
  text-align: justify;
  text-justify: inter-word;
  word-break: break-all;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.justify_me{
  text-align: justify;
  text-justify: inter-word;
  word-break: break-all;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.no_justify{
  text-align: initial !important;
} */
.Center{
  display: flex;
  align-items: center;
}
button{
  border: none;
}
.alert{
  padding: 10px 15px;
}
input{
  outline: none !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1440px ;
  margin: 0 auto;
}
.fonts1{
  font-family: 'Climate Crisis', cursive;
}
.fonts10{
    font-family: 'Tilt Prism', cursive;
}
.fonts9{
    font-family: 'Sono', sans-serif;
}
.fonts8{
    font-family: 'Sacramento', cursive;
}
.fonts7{
    font-family: 'Rubik Iso', cursive;
}
.fonts6{
    font-family: 'Rampart One', cursive;
}
.fonts5{
    font-family: 'Raleway', sans-serif;
}
.fonts4{
    font-family: 'Permanent Marker', cursive;
}
.fonts2{
  font-family: 'Fredoka One', cursive;
}
.fonts3{
  font-family: 'Pacifico', cursive;
}
.pl_05{
    padding-left: 5px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.color{
  color: #2E343B;
}
a{
  text-decoration: none;
  color: #2E343B;
}
img{
  max-width: 100%;
}


.rouselContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.main_rouselContainer{
  display: flex;
  overflow-x: scroll;
  grid-gap: 12px;
  gap: 12px
}
.hideScrollbar{
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
}
.main_rouselContainer img{
  width: 100%;
  height: 100%;
  width: 380px;
  height: 500px;
  display: flex;
  object-fit: cover;
  transition: transform 0.5s;
  cursor: pointer;
}

.left_btn{
  position: absolute;
  left: 20px;
  width: 50px;
  margin: auto 0;
  opacity: 0.5;
  border-radius: 100%;
  &:hover{
    opacity: 1;
  }
}
.right_btn{
  position: absolute;
  right: 20px;
  width: 50px;
  margin: auto 0;
  opacity: 0.5;
  border-radius: 100%;
  &:hover{
    opacity: 1;
  }
}
.gap_form{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: column;
}
.btn_secondarys{
  padding: 7px 20px;
  border-radius: 6px;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid #343A40;
  color: #343A40;
  background: transparent;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  &:hover{
    background: #959ca3;
  }
}
.btn_secondarys_main{
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 700;
  max-width: 300px;
  cursor: pointer;
  border: 1px solid #343A40;
  color: #fff;
  background: #343A40;
  margin: 0 auto;
  transition: background-color 0.3s ease;
  &:hover{
    background-color: #fff;
    color: #000;
  }
}
.rotate_45{
  max-width: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.flex_col{
  display: flex;
  flex-direction: column;
}
.standard_width_sm form {
  width: auto;
  min-width: auto;
  align-self: center;
  align-self: auto;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}
/* general.............................................. */
.d_block{
  display: block;
}

.pt_15{
  padding-top: 15px;
}
.border_right{
  border-right: 1px solid #f1f1f1;
}
.btn_info{
  background-color: #007BFF;
  padding: 7px 20px;
  color: #fff;
  border-radius: 6px;
  font-weight: 700;
}
.btn{
  background-color: #f1f1f1;
  padding: 7px 20px;
  border-radius: 6px;
  font-weight: 700;
}
.btn_danger{
  background-color: #DC3545;
  padding: 7px 20px;
  border-radius: 6px;
  font-weight: 700;
}

.equo_Two{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.bg_warning{
  background-color: #FFF3CD;
}
.bg_primary{
  background-color: #CCE5FF;
}
.bg_success{
  background-color: #D4EDDA;
}
.bg_info{
  background-color: #007BFF;
}
.bg_blur{
  background-color: #f2f2f2;
}
.my_15{
  margin: 5px 0;
}
.w_100{
  width: 100%;
}
.warning{
  color: #FFC107;
}
.success{
  color: #28A745;
}
.danger{
  color: #DC3545;
}
.z_index{
  z-index: 5;
}
.dark{
  color: black !important;
}
.pointer{
  cursor: pointer;
}

.d_none{
  display: none;
}
.right_auto{
  margin-right: auto;
}
.left_auto{
  margin-left: auto;
}
.shadow-smup {
  box-shadow: 0 .15rem .25rem rgba(0,0,0,.075);
}
div.sticky_top{
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 0 !important;
  background-color: #fff;
  z-index: 696;
}

.shadow_sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}
.shadow_lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
}
.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.Rounded{
  border-radius: 10px;
}
.Rounded_lg{
  border-radius: 15px;
}
.Rounded_sm{
  border-radius: 5px;
}
.border_right{
  border-right: 1px solid #fff;
}
.float_right{
  float: right;
}
.w-full{
  width: 100%;
}
.center2{
  margin: auto;
}
.center3{
  margin: auto 0;
}

.centerx{
  margin: 0 auto;
}
.flex{
  display: flex;
}
/* .ul{
  list-style: none;
}
.ul li:before {
  content: '✓';
  color: #fff;
  display: inline-block; 
  width: 1em ;
  margin-left: -1em;
  margin-right: 30px;
} */
.white{
  color: #fff;
}
.font_20_b{
  font-size: 20px;
  font-weight: 800;
}
.font_18_b{
  font-size: 18px;
  font-weight: 800;
}
.font_12{
  font-size: 13px;
}
.font_12_b{
  font-size: 13px;
  font-weight: 800;
}
a:hover{
  /* color: #E5E5E5; */
  text-decoration: none;
}
.h_min{
  min-height: 160px;
}


/* general........................................ */
.pt_05{
  padding-top: 5px;
}
.h_100{
  height: 100% !important;
}
.relative{
  position: relative;
}
.mt_05{
  margin-top: 5px;
}
.mt_15{
  margin-top: 15px;
}
.mt_3{
  margin-top:40px;
}
.mt_4{
  margin-top: 50px;
}
.mt_5{
  margin-top: 60px;
}
.mt_6{
  margin-top: 70px;
}
.mt_7{
  margin-top: 80px;
}
.mt_8{
  margin-top: 90px;
}
.mt_9{
  margin-top: 100px ;
}
.pt_6{
  padding-top: 70px;
}
.pt_7{
  padding-top: 80px;
}
.pt_8{
  padding-top: 90px;
}
.pt_9{
  padding-top: 100px;
}
.mt_2{
  margin-top:20px;
}
.font_11{
  font-size: 11px;
}
.font_10{
  font-size: 10px;
}
.font_112{
  font-size: 12px;
}
.font_13{
  font-size: 13px;
}
.font_14{
  font-size: 14px;
}
.font_15{
  font-size: 15px;
}
.font_16{
  font-size: 16px;
}
.font_18{
  font-size: 18px;
  
}
.font_20{
  font-size: 20px;
  
}
.font_22{
  font-size: 22px;
  
}
.font_24{
  font-size: 24px;
  
}
.font_26{
  font-size: 26px;
  
}
.font_28{
  font-size: 28px;
  
}
.font_32{
  font-size: 32px;
  
}
.font_34{
  font-size: 34px;
  
}
.font_35{
  font-size: 35px;
  
}
.font_35{
  font-size: 35px;
  
}

.font_36{
  font-size: 36px;
  
}
.font_38{
  font-size: 38px;
  
}
.font_40{
  font-size: 40px;
  
}
.font_45{
  font-size: 45px;
  
}
.font_50{
  font-size: 50px;
  
}
.bold4{
  font-weight: 400;
}
.bold5{
  font-weight: 500;
}
.bold6{
  font-weight: 600;
}
.bold7{
  font-weight: 700;
}
.bold8{
  font-weight: 800;
}
.bold9{
  font-weight: 900;
}
.center{
  text-align: center;
}
.center3{
  margin: auto 0;
}
.inline-block{
  display: inline-block;
}
.mr_1{
  margin-right: 10px;
}
.mr_2{
  margin-right: 20px;
}
.mr_3{
  margin-right: 30px;
}
.mr_4{
  margin-right: 40px;
}
.mr_5{
  margin-right: 50px;
}
.mr_6{
  margin-right: 60px;
}
.mr_7{
  margin-right: 70px;
}

.mx_1{
  margin: 0 10px ;
}
.mx_2{
  margin: 0 20px ;
}
.mx_3{
  margin: 0 30px ;
}
.mx_4{
  margin: 0 40px ;
}
.mx_5{
  margin: 0 50px ;
}
.mx_6{
  margin: 0 60px ;
}
.mx_7{
  margin: 0 70px ;
}


.my_1{
  margin: 10px 0;
}
.my_2{
  margin: 20px 0;
}
.my_3{
  margin: 30px 0;
}
.my_4{
  margin: 40px 0;
}
.my_5{
  margin: 50px 0;
}
.my_6{
  margin: 60px 0;
}
.my_7{
  margin: 70px 0;
}


.ml_1{
  margin-left: 10px;
}
.ml_2{
  margin-left: 20px;
}
.ml_3{
  margin-left: 30px;
}
.ml_4{
  margin-left: 40px;
}
.ml_5{
  margin-left: 50px;
}
.ml_6{
  margin-left: 60px;
}
.ml_7{
  margin-left: 70px;
}



.mb_1{
  margin-bottom: 10px;
}
.mb_2{
  margin-bottom: 20px;
}
.mb_3{
  margin-bottom: 30px;
}
.mb_4{
  margin-bottom: 40px;
}
.mb_5{
  margin-bottom: 50px;
}
.mb_6{
  margin-bottom: 60px;
}
.mb_7{
  margin-bottom: 70px;
}





.mt_1{
  margin-top: 10px;
}
.mt_15{
  margin-top: 15px;
}
.mt_2{
  margin-top: 20px;
}
.mt_3{
  margin-top: 30px;
}
.mt_4{
  margin-top: 40px;
}
.mt_5{
  margin-top: 50px;
}
.mt_6{
  margin-top: 60px;
}
.mt_7{
  margin-top: 70px;
}

.mt_8{
  margin-top: 80px;
}
.mt_9{
  margin-top: 90px;
}








.pr_1{
  padding-right: 10px;
}
.pr_2{
  padding-right: 20px;
}
.pr_3{
  padding-right: 30px;
}
.pr_4{
  padding-right: 40px;
}
.pr_5{
  padding-right: 50px;
}
.pr_6{
  padding-right: 60px;
}
.pr_7{
  padding-right: 70px;
}



.pl_1{
  padding-left: 10px;
}
.pl_2{
  padding-left: 20px;
}
.pl_3{
  padding-left: 30px;
}
.pl_4{
  padding-left: 40px;
}
.pl_5{
  padding-left: 50px;
}
.pl_6{
  padding-left: 60px;
}
.pl_7{
  padding-left: 70px;
}



.pb_1{
  padding-bottom: 10px;
}
.pb_2{
  padding-bottom: 20px;
}
.pb_3{
  padding-bottom: 30px;
}
.pb_4{
  padding-bottom: 40px;
}
.pb_5{
  padding-bottom: 50px;
}
.pb_6{
  padding-bottom: 60px;
}
.pb_7{
  padding-bottom: 70px;
}



.pt_1{
  padding-top: 10px;
}
.pt_2{
  padding-top: 20px;
}
.pt_3{
  padding-top: 30px;
}
.pt_4{
  padding-top: 40px;
}
.pt_5{
  padding-top: 50px;
}
.pt_6{
  padding-top: 60px;
}
.pt_7{
  padding-top: 70px;
}





.px_1{
  padding: 0 10px ;
}
.px_2{
  padding: 0 20px ;
}
.px_3{
  padding: 0 30px ;
}
.px_4{
  padding: 0 40px ;
}
.px_5{
  padding: 0 50px ;
}
.px_6{
  padding: 0 60px ;
}
.px_7{
  padding: 0 70px ;
}

.py_05{
  padding: 5px 0;
}
.py_1{
  padding: 10px 0;
}
.py_2{
  padding: 20px 0;
}
.py_3{
  padding: 30px 0;
}
.py_4{
  padding: 40px 0;
}
.py_5{
  padding: 50px 0;
}
.py_6{
  padding: 60px 0;
}
.py_7{
  padding: 70px 0;
}
@media (max-width:760px) {
  .gap_form{
    grid-gap: 0;
    gap: 0;
  }
  .standard_width_sm .p-5{
    padding: 15px !important;
  }
  .main_rouselContainer img{
    width: 100px;
    height: 150px;
  }
  .main_rouselContainer{
    grid-gap: 7px;
    gap: 7px
  }
  .left_btn{
    left: 10px;
    width: 45px;
    height: 45px;
  }
  .right_btn{
    right: 10px;
    width: 45px;
    height: 45px;
  }
  .row {
    --bs-gutter-x: 0;
  }
  .gap_form{
    font-size: 14px;
  }
}

.strip #root {
    display: flex;
    align-items: center;
  }
  
.strip {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 100vh;
    width: 100vw;
    padding: 20px;
  }
.payMentSuccess{
    display: block;
    position: absolute;
    top: 0;
    text-align: center;
}
  form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  button:hover {
    filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    transform-origin: 10.4px 10.2px;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    transform-origin: 0px 10.2px;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
      padding: 15px;
    }
  }
.Coverr {
    height: 100%;
    width: 100%;
    margin: 0px;
    background: linear-gradient(90deg, #2f3640 23%, #181b20 100%);
}

.moon {
    background: linear-gradient(90deg, #d0d0d0 48%, #919191 100%);
    position: absolute;
    top: -100px;
    left: -300px;
    max-width: 900px;
    width: 100%;
    height: 900px;
    content: '';
    border-radius: 100%;
    box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.5);
}

.moon__crater {
    position: absolute;
    content: '';
    border-radius: 100%;
    background: linear-gradient(90deg, #7a7a7a 38%, #c3c3c3 100%);
    opacity: 0.6;
}

.moon__crater1 {
    top: 250px;
    left: 500px;
    width: 60px;
    height: 180px;
}

.moon__crater2 {
    top: 650px;
    left: 340px;
    width: 40px;
    height: 80px;
    transform: rotate(55deg);
}

.moon__crater3 {
    top: -20px;
    left: 40px;
    width: 65px;
    height: 120px;
    transform: rotate(250deg);
}

.star {
    background: grey;
    position: absolute;
    width: 5px;
    height: 5px;
    content: '';
    border-radius: 100%;
    transform: rotate(250deg);
    opacity: 0.4;
    animation-name: shimmer;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes shimmer {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}

.star1 {
    top: 40%;
    left: 50%;
    animation-delay: 1s;
}

.star2 {
    top: 60%;
    left: 90%;
    animation-delay: 3s;
}

.star3 {
    top: 10%;
    left: 70%;
    animation-delay: 2s;
}

.star4 {
    top: 90%;
    left: 40%;
}

.star5 {
    top: 20%;
    left: 30%;
    animation-delay: 0.5s;
}

.error {
    position: absolute;
    left: 100px;
    top: 400px;
    transform: translateY(-60%);
    font-family: 'Righteous', cursive;
    color: #363e49;
}

.error__title {
    font-size: 10em;
}

.error__subtitle {
    font-size: 2em;
}

.error__description {
    opacity: 0.5;
}

.error__button {
    min-width: 7em;
    margin-top: 3em;
    margin-right: 0.5em;
    padding: 0.5em 2em;
    outline: none;
    border: 2px solid #2f3640;
    background-color: transparent;
    border-radius: 8em;
    color: #576375;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 0.75em;
    font-family: 'Righteous', cursive;
}

.error__button:hover {
    color: #21252c;
}

.error__button--active {
    background-color: #e67e22;
    border: 2px solid #e67e22;
    color: white;
}

.error__button--active:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    color: white;
}

.astronaut {
    position: absolute;
    width: 185px;
    height: 300px;
    left: 70%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
}

.astronaut__head {
    background-color: white;
    position: absolute;
    top: 60px;
    left: 60px;
    width: 60px;
    height: 60px;
    content: '';
    border-radius: 2em;
}

.astronaut__head-visor-flare1 {
    background-color: #7f8fa6;
    position: absolute;
    top: 28px;
    left: 40px;
    width: 10px;
    height: 10px;
    content: '';
    border-radius: 2em;
    opacity: 0.5;
}

.astronaut__head-visor-flare2 {
    background-color: #718093;
    position: absolute;
    top: 40px;
    left: 38px;
    width: 5px;
    height: 5px;
    content: '';
    border-radius: 2em;
    opacity: 0.3;
}

.astronaut__backpack {
    background-color: #bfbfbf;
    position: absolute;
    top: 90px;
    left: 47px;
    width: 86px;
    height: 90px;
    content: '';
    border-radius: 8px;
}

.astronaut__body {
    background-color: #e6e6e6;
    position: absolute;
    top: 115px;
    left: 55px;
    width: 70px;
    height: 80px;
    content: '';
    border-radius: 8px;
}

.astronaut__body__chest {
    background-color: #d9d9d9;
    position: absolute;
    top: 140px;
    left: 68px;
    width: 45px;
    height: 25px;
    content: '';
    border-radius: 6px;
}

.astronaut__arm-left1 {
    background-color: #e6e6e6;
    position: absolute;
    top: 127px;
    left: 9px;
    width: 65px;
    height: 20px;
    content: '';
    border-radius: 8px;
    transform: rotate(-30deg);
}

.astronaut__arm-left2 {
    background-color: #e6e6e6;
    position: absolute;
    top: 102px;
    left: 7px;
    width: 20px;
    height: 45px;
    content: '';
    border-radius: 8px;
    transform: rotate(-12deg);
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
}

.astronaut__arm-right1 {
    background-color: #e6e6e6;
    position: absolute;
    top: 113px;
    left: 100px;
    width: 65px;
    height: 20px;
    content: '';
    border-radius: 8px;
    transform: rotate(-10deg);
}

.astronaut__arm-right2 {
    background-color: #e6e6e6;
    position: absolute;
    top: 78px;
    left: 141px;
    width: 20px;
    height: 45px;
    content: '';
    border-radius: 8px;
    transform: rotate(-10deg);
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
}

.astronaut__arm-thumb-left {
    background-color: #e6e6e6;
    position: absolute;
    top: 110px;
    left: 21px;
    width: 10px;
    height: 6px;
    content: '';
    border-radius: 8em;
    transform: rotate(-35deg);
}

.astronaut__arm-thumb-right {
    background-color: #e6e6e6;
    position: absolute;
    top: 90px;
    left: 133px;
    width: 10px;
    height: 6px;
    content: '';
    border-radius: 8em;
    transform: rotate(20deg);
}

.astronaut__wrist-left {
    background-color: #e67e22;
    position: absolute;
    top: 122px;
    left: 6.5px;
    width: 21px;
    height: 4px;
    content: '';
    border-radius: 8em;
    transform: rotate(-15deg);
}

.astronaut__wrist-right {
    background-color: #e67e22;
    position: absolute;
    top: 98px;
    left: 141px;
    width: 21px;
    height: 4px;
    content: '';
    border-radius: 8em;
    transform: rotate(-10deg);
}

.astronaut__leg-left {
    background-color: #e6e6e6;
    position: absolute;
    top: 188px;
    left: 50px;
    width: 23px;
    height: 75px;
    content: '';
    transform: rotate(10deg);
}

.astronaut__leg-right {
    background-color: #e6e6e6;
    position: absolute;
    top: 188px;
    left: 108px;
    width: 23px;
    height: 75px;
    content: '';
    transform: rotate(-10deg);
}

.astronaut__foot-left {
    background-color: white;
    position: absolute;
    top: 240px;
    left: 43px;
    width: 28px;
    height: 20px;
    content: '';
    transform: rotate(10deg);
    border-radius: 3px;
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
    border-bottom: 4px solid #e67e22;
}

.astronaut__foot-right {
    background-color: white;
    position: absolute;
    top: 240px;
    left: 111px;
    width: 28px;
    height: 20px;
    content: '';
    transform: rotate(-10deg);
    border-radius: 3px;
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
    border-bottom: 4px solid #e67e22;
}
@media (max-width: 930px) {
    .moon {
        left: -100px;
    }
}

@media (max-width: 730px) {
    .moon {
        left: -40px;
    }
}

@media (max-width: 600px) {
    .astronaut {
        left: 20%;
        top: 20%;
    }
    .moon__crater1 {
        top: 250px;
        left: 150px;
    }
    
}
@media (max-width: 500px) {
    .moon__crater2 {
        /* top: 650px; */
        left: 150px;
    }
    .error {
        left: 20px;
        top: 400px;
        right: 20px;
    }
}
@media (max-width: 400px) {
    .error__title {
        font-size: 7em;
        z-index: 20;
    }
    .astronaut {
        left: 70%;
    }
    
}
.standard_width{
    max-width: 1200px;
    width: 100%;
    padding: 15px 20px;
    margin: auto;
}
.standard_width_sm{
    max-width: 900px;
    width: 100%;
    padding: 15px 20px;
    margin: auto;
}
.MyNavDropDown{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}
/* .translatorcss_s{
    transform: translate(-300px);
} */
.translatorcss{
    /* background-color: rgb(189,18,61,.1);
    height: 100%; */
    padding: 15px 20px;
}
.pad_width{
    padding: 10px 0 30px 10px ;
}
.link_container{
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    /* text-align: center; */
    border-bottom: 1px solid #f1f1f1;
}
.navbaR span{
    max-width: 120px !important;
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 500;
}
.right_nav{
    max-width: 960px;
    width: 100%;
}
.sign_up_btn{
    background-color: #3491E8;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}
.login_btn{
    color: #cf0c36;
    font-size: 14px;
    font-weight: 500;
}
.right_nav span samp img{ 
    margin-top: -4.5px;
    margin-left: 2px;
    display: inline-block;
    /* background-color: #cf0c36; */
}

.hover_Effect .hover_Effect2,
.hover_Effect .hover_Effect3,
.hover_Effect .hover_Effect4,
.hover_Effect .hover_Effect5{
    display: none;
}
.hover_Effect:hover .hover_Effect2,
.hover_Effect:hover .hover_Effect3,
.hover_Effect:hover .hover_Effect4,
.hover_Effect:hover .hover_Effect5{
    display: block;
}

.hover_Effect:hover img,
.hover_Effect:hover .saxm img,
.hover_Effect:hover .saxm2 img,
.hover_Effect:hover .saxm3 img{
    transform: rotate(180deg);
    padding-left: 0;
}

.hover_Effect2,
.hover_Effect3,
.hover_Effect4,
.hover_Effect5{
    position: absolute;
    z-index: 100000 !important;
}
.hover_Effect{
    position: relative;
}

.drop_Down_container{
    background-color: #fff;
    margin-top: 10px;
    min-width: 150px;
    width: 100%;
    position: relative;
    border-radius: 10px;
    padding: 10px;
}
.drop_Down_container p{
    text-align: initial;
}
.cartValue{
    position: absolute;
    top: -5px;
    right: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    background-color: #cf0c36;
    /* padding: 2px; */
    border-radius: 100%;
    height: 18px;
    width: 18px;
    text-align: center;
    align-items: center;
}
.cartValue2{
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    background-color: #cf0c36;
    /* padding: 2px; */
    border-radius: 100%;
    height: 18px;
    width: 18px;
    text-align: center;
    align-items: center;
    margin-left: 5px;
}
.navbaR samp{
    display: none;
}
.logoContentContaIner img{
    max-width: 50px;
}
.contestPage{
    position: relative;
    max-height: 500px;
    text-align: center;
}
.contestPage .imgContest{
    max-height: 500px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.userIg{
    margin-top: -40px;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-left: 70px;
    position: relative;
    z-index: 10;
}
.userIg2{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-left: 70px;
    position: relative;
    z-index: 10;
}
.contestDescription{
    margin-left: 70px;
    z-index: 5;
}
.contestImg{
    display: flex;
}
.contestList img.rounded{
    height: 270px;
    width: 100%;
    border-radius: 0.375rem 0.375rem 0 0 !important;
}
.borderTops{
    border-top: 5px solid #CF0C36;
}
.contestList button:hover,
button.vote:hover{
    background-color: #CF0C36 !important;   
}
.contestantsView{
    max-width: 500px;
    width: 100%;
    margin: auto;

}
.payBorder{
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;

}
.payBorder input,.payBorder select{
    border: none;
    outline: none;
}
.max_ContestImgH img{
    max-height: 400px;
}
.justify_between{
    display: flex;
    justify-content: space-between;
}

.VAR .carousel-indicators .active {
    opacity: 1;
    background-color: #CF0C36;
}

.max_width_successImg{
    max-width: 150px !important;
}
.timerHolderContainer{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 10px;
    max-width: 350px;

}
.timerHolderContainer img{
    margin-top: 12px;
}
.firstCOverTimer{
    border: 0.5px solid #E53E7C;
    background-color: #EA98B9;
    padding: 5px ;
    border-radius: 10px;
    display: block;
}
.firstCOverTimer span{
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    /* margin: 2px 12px; */
    padding: 4px 9px;
    border-radius: 10px;
    background-color: #E93F7E;
    width:38px;
    text-align: center;
    display: inline-block;
}
.navbaR a.px_2{
    padding: 0 10px !important;
}
@media (max-width:1000px) {
    .right_nav *{
        font-size: 11px !important;
        margin-top: auto;
        margin-bottom: auto;
    }
    .navbaR span{
        max-width: 90px !important;
        padding: 0 7px;
    }
    .navbaR a.px_2{
        padding: 0 10px;
    }
    
}
@media (max-width:940px) {
    .navbaR span{
        max-width: 80px !important;
        padding: 0 7px;
        font-size: 12px;
    }
    .navbaR a.px_2{
        padding: 0 10px;
    }
}
@media (max-width:820px) {
    .navbaR span{
        display: none;
    }
    .navbaR samp{
        display: inline-block;
        padding-left: 15px;
    }
    .navbaR .login_btn{
        display: none;
    }
    .navbaR .sign_up_btn{
        display: none;
    }
    .userIg,.userIg2{
        width: 150px;
        height: 150px;
        margin-left: 20px;
    }
    .contestImg{
        display: block;
    }
    .contestDescription{
        margin-left: 0;
        margin-top: 30px;
    }
    .VAR .font_32{
        font-size: 22px;
    }
    .VAR .font_22{
        font-size: 18px ;
    }
    
}

@media (max-width:550px) {
    .logoContentContaIner img{
        max-width: 40px;
    }
    .userIg,.userIg2{
        width: 100px;
        height: 100px;
    }
    .contestDescription{
        margin-left: 0;
        margin-top: 20px;
    }
    /* .VARR .shop{
        grid-template-columns: 1fr;
    } */
    .flex.lil{
        display: block;
    }
    .flex.lil .left_auto{
        margin-top: 15px;
    }
    .contestList img.rounded{
        height: 150px;
        width: 100%;
        border-radius: 0.375rem 0.375rem 0 0 !important;
    }
}

.search_container{
    max-width: 500px;
    border-radius: 4px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    height: 40px;
    display: flex;
}

.search_container input,
.search_container select{
    height: 100%;
    width: 100%;
    padding: 0 10px;
    border: none;
    outline: none;
    -webkit-appearance: none;
            appearance: none;
}
.search_container span{
    background-color: #3491E8;
    padding: 2px 15px;
    border-radius: 4px;
    margin: 4px;
}
.standard_in{
    max-width: 1000px;
    width: 100%;
    margin: auto;
}
.standard_in .smd{
    color: #3491E8;
    font-size: 15px;
    font-weight: 700;
}
.ml_01{
    margin-left: 5px;
}
.standard_in .smx{
    padding: 0 20px;
    font-size: 15px;
    font-weight: 700;
}
.myCaurosel{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 10px;
}
.inside_id{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.inside_id img{
    border-radius: 10px;
}
.botBorder img{
    max-height: 200px;
    width: 100%;
    border-radius: 10px;
}
.inside_id2{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.thres img{
    max-height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
}
.imgOnes{
    border-radius: 10px 0 0 0;
    margin-bottom: 0.5px;
}
.imgtwos{
    border-radius: 0 0 0 10px;
    margin-top: 0.5px;
}
.imgThres{
    border-radius: 0 10px 10px 0;
    height: inherit;
}

.LastColImg img{
    height: inherit;
    /* height: 100%; */
    border-radius: 10px;
}
.pl_011{
    padding-left: 3px;
}
.join_now_btn{
    border-radius: 10px;
    background-color: rgb(207,12,54,0.2);
    padding: 4px;
    height: 44px;
    max-width: 200px;
    display: flex;
    width: 100%;
}
.join_now_btn span{
    color: #cf0c36;
    font-weight: 500;
    text-align: center;
    width: 100%;
    padding-top: 5.5px;
}
.join_now_btn samp{
    font-weight: 500;
    text-align: center;
    width: 100%;
    background: linear-gradient(to right, rgb(207,12,54,0.8), rgb(207,12,54));
    height: 100%;
    padding-top: 5.5px;
    width: 44px;
    border-radius: 9px;
}
.about{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
}
.about_pix_container{
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: 10px;
}
.aboutPix1 img{
    /* max-height: 450px;
    height: 100%; */
    border-radius: 10px;
    width: 100%;
    margin-left: auto;
}

.aboutPix2 img{
    max-height: 210px;
    border-radius: 10px;
    margin-left: auto;
    margin-top: 10px;
    max-width: 200px;
}
.aboutPix3 img{
    border-radius: 10px;
    max-width: 250px;
}
.aboutPix4 img{
    border-radius: 10px;
    max-height: 350px;
}
.padAbtpix{
    padding-top: 120px;
}
.bg_ashy{
    background-color: #f1f1f1;
}
.reviews{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}

.taking{
    max-width: 700px;
    width: 100%;
    margin: auto;
}
.reviewContent{
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 10px;
    position: relative;
}
.profileReview{
    height: 70px;
    width: 70px;
    border-radius: 100%;
    display: block;
    position: absolute;
    bottom: -30px;
    right: 50px;
    border: 1px solid #d5d2d2;
    padding: 5px;
}
.logoIndustries{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.logoIndustries img{
    max-height: 100px;
}
.newsletterContainer{
    max-width: 800px;
    background-color: #fff;
    padding: 50px 60px;
    margin: 20px auto 50px auto;
    border-radius: 5px;
}
.newsLetterText{
    font-size: 22px;
    color: #cf0c36;
}
.newLetterInpuContainer{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    margin-top: 10px;
    display: flex;
    padding: 2.5px;
    height: 45px;
}
.newLetterInpuContainer input{
    width: 100%;
    border: none;
    padding: 0 10px;
}
.newLetterInpuContainer button{
    border-radius: 9px;
    height: 100%;
    max-width: 150px;
    background-color: #cf0c36;
    color: #fff;
    font-weight: 500;
    width: 100%;
    display: inline-block;
    text-align: center;
    border: none;
    text-decoration: none;
    outline: none;
    padding: 0;
}

.carodisplay{
    display: block;
}
.carodisplay2{
    display: none;
}
.cauroselRefReview{
    display: none;
}
.flexImgs{
    display: flex;
    align-items: center;
}

.newsHeaderImg img{
    width: 100px !important;
    max-height: 450px;

}
.newsHeaderImg{
    display: flex;
    padding: 0 20px;
}
.newsHeaderImg img{
    max-height: 80px;
}
@media (max-width:900px) {
    .newsHeaderImg{
        flex-wrap: wrap;
        flex-direction: column;
        padding: 20px;
    }
    .newsHeaderImg .center2{
        margin: initial;
    }
}
@media (max-width:768px) {
    .reviewContent .font_12,.reviewContent .font_14{
        font-size: 11px !important;
    }
}
@media (max-width:718px) {
    .reviews{
        display: none;
    }
    .cauroselRefReview{
        display: block;
        
    }
    .reveiwCarouselContent{
        padding-bottom: 100px;
        min-height: 500px;
    }
    
}
@media (max-width:600px) {
    .font_36{
        font-size: 26px;
    }
    
}
@media (max-width:500px) {
    .standard_in .smd{
        font-size: 11px;
        font-weight: 600;
    }
    .reveiwCarouselContent{
        min-height: 620px;
    }
    .standard_in .smx{
        padding: 0 5px;
        font-size: 11px;
        font-weight: 600;
    }
    .carodisplay{
        display: none;
    }
    .carodisplay2{
        display: block;
    }
    .myCaurosel{
        grid-template-columns: 4fr 2fr;
        grid-gap: 10px;
    }

    .join_now_btn{
        height: 38px;
        max-width: 150px;
    }
    .join_now_btn span{
        padding-top: 3.5px;
        font-size: 14px;
    }
    .join_now_btn samp{
        padding-top: 3.5px;
        width: 38px;
    }
    .aboutPix3 img{
        max-width: 100%;
    }
    .newsLetterText{
        font-size: 19px;
    }
    .newLetterInpuContainer{
        padding: 2px;
        height: 40px;
    }
    
    .newLetterInpuContainer button{
        max-width: 100px;
        font-size: 12px;
    }
    .newLetterInpuContainer input{
        font-size: 12px;
    }
    .newsletterContainer{
        padding: 40px 20px;
    }
    
}

@media (max-width:450px) {
    .blue.font_34,.red.font_34{
        font-size: 24px;
    }
    .about_pix_container{
        grid-template-columns: 1fr;
    }
    .aboutPix2 img{
        max-height: 100%;
        /* margin-left: auto; */
        margin-top: 10px;
        max-width: 100%;
    }
    .pt_6.aboutPix3{
        padding-top: 0;
    }

}

@media (max-width:350px) {
    .newsletterContainer{
        padding: 40px 15px;
    }
}






















.aboutLander2{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, #fff, #FAD4C1);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding: 35px;
}
.selectedForm{
    border: none !important;
    min-width: 130px;
    outline: none;
}
.colorDisplayer{
    display: inline-block;
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 20px;
}
.vote_receipt_container{
    max-width: 300px;
    display: flex;
    border: 1px solid #f1f1f1;
    /* padding-left: ; */
    padding: 3px 3px 3px 10px ;
}
.vote_receipt_container input{
    border: none !important;
}
.receipt_content{
    max-width: 820px;
    width: 100%;
    padding: 30px;
    background-color: #FFFFFF;
    margin: 0 auto;
}
.receipt_header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
.receipt_header small{
    font-weight: 600;
}
.receipt_services_header{
    max-width: 200px;
    font-weight:700;
    font-size: 32px;
    line-height: 1.3;
    text-align: right;
    color: #004188;
}
.receipt_color{
    color: #004188;
}
.fonted_600{
    font-weight: 600;
}
.receipt_date_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    grid-gap: 15px;
    gap: 15px;
}
.receipt_banner{
    padding: 7px 10px;
    background-color: #004188;
    color: #FFFFFF;
    
}
.text_align_right{
    text-align: right;
}
.receipt_banner_gridded{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
}
.z-10{
    z-index: 10;
}
.receipt_smallHeader{
    font-size: 10px;
    font-weight: 800;
    color: #004188;
}



.optspan{
    width: 250px;
    display: inline-block;
}
.rotate{
    transform: rotate(180deg);
    transition: transform .5s;
}
.border_radius{
    border-radius: 10px;
}
.FlexModal{
    display: flex;
    flex-wrap: wrap;
}
.border_filter{
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.borderRight{
    border-right: 1px solid #f1f1f1;
}
.borderRight:hover{
    background-color: #f1f1f1;
    cursor: pointer;
}
.shop{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 40px;
}
.shop_item{
    border-radius: 10px;
    background-color: #fff;
    padding: 4px;
}
.shop_description{
    background-color: rgb(189,18,61,.1);
    border-radius: 10px;
    padding: 20px
}
.add_to_cart{
    background-color: rgb(189,18,61,.7);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    color: #fff;
    align-items: center;
    font-size: 24px;
    position: relative;
}
.add_to_cart div{
    position: absolute;
    top: -5px;
    left: 7px;
}

.townHall div:first-child{
    background-color: blue;
    color: blue;
}
.different.from.balaBlue{
    color: #3491E8;
}
.clothes .shop_description{
    background-color: rgb(52,145,232,.1);
}

.clothes .add_to_cart{
    background-color: rgb(52,145,232,.7);
}
.clothes .red{
    color: #3491E8;
}
.blue{
    color: #3491E8;
}

.haefa .carousel-indicators button{
    width: 5px !important;
    border-radius: 100%;
}


.homeBased .carousel-indicators button,
.reviews .carousel-indicators button{
    display: none !important;
}
.homeBased .carousel-control-prev,
.homeBased .carousel-control-next {
    background-color: rgb(207,12,54,0.5) ;
    height: 50px;
    width: 50px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100px;
}
.haefa .carousel-control-prev-icon,
.carousel-control-next-icon{
    /* display: none !important; */
}


.homeBased .carousel-control-prev-icon,
.carousel-control-next-icon{
    /* display: none !important; */
}

.aboutLander2 img{
    max-height: 406px;
}
.aboutLander2.xdf{
    background: linear-gradient(to right,#fff, #fff);
}
.classLanding_page{
    background: linear-gradient(to right, rgb(52,145,232,.09), #fff);
    width: 100%;
    min-height: 400px;
    
    padding: 35px;
}
.classDivider{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    max-width: 900px;
    margin: 0 auto;
}
.ClassHeader{
    font-size: 40px;
    font-weight: 200;
    line-height: 1.2;
}
.classesDescHeader{
    line-height: 1.2;
    font-size: 35px;
    font-weight: 800;
}
.diveid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    position: absolute;
    bottom: -120px;
    padding: 20px;
}
.text_align_center{
    padding-top: 40px;
}
.diveid img{
    max-width: 180px;
    border-radius: 4px;
}
.model_class_CONtainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin-top: 40px;
}
.mageHeader{
    background-color: #3491E8;
    border-radius: 10px;
    padding: 20px;
    display: flex;
}
.upcoming_mag_Btn2{
    color: #fff;
    background-color: rgb(207,12,54,.9);
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    padding: 8px 20px;
}
.upcoming_mag_Btn2:hover{
    color: #fff;
}
.upcoming_mag_Btn_iii{
    color: #fff;
    background-color: #CF5271;
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 20px;
    width: 100%;
    padding: 8px 20px;
}
.magazin_covers{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 35px;
    margin-top: 40px;
}

.mag{
    border-radius: 10px;
}
.mag img{
    border-radius: 10px 10px 0 0;
}
.aboutLander2fx{
    width: 100%;
    min-height: 500px;
    align-items: center;
    /* background: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)),url(../Css/Images/andrea-mininni-VLlkOJdzLG0-unsplash.jpg); */
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    padding: 35px;
    padding-top: 70px;
}
.error {
    /* position: absolute; */
    position: initial;
    left: initial;
    top: initial;
    transform: initial;
    font-family: inherit;
    /* color: #363e49; */
}
.aboutLander2fx.x_2{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)),url(/static/media/halil-ibrahim-cetinkaya-lbBrOujiO-Q-unsplash.b1d66092.jpg);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    padding: 35px;
    padding-top: 70px;
}
.aboutLander2fx.x_3{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)),url(/static/media/md-duran-rE9vgD_TXgM-unsplash.81f464e8.jpg);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    padding: 35px;
    padding-top: 70px;
}
.eventGridContainer{
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}
.evntCont{
    border-radius: 10px;
}
.evntCont img{
    border-radius: 10px 10px 0 0 ;
}
.upcoming_Eevent_btn{
    display: block;
    text-align: center;
    padding: 8px 20px;
    color: #fff;
    font-size: 14px;
    background-color: #3491E8;
    border-radius: 7px;
    border: none;
    width: 100%;
}
.upcoming_Eevent_btn:hover{
    color: #fff;
}
.eventImgContainer img{
    max-height: 500px;
    /* width: 100%; */
    display: inline-block;
    margin: 0 auto;
    border-radius: 10px;
}
.eventImgContainer{
    display: flex;
    /* align-items: center; */
}
.eventIckets_btn{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    padding: 8px 20px;
    max-width: 180px;
    text-align: center;
    display: block;
    margin-top: 10px;
}
.standardEvent{
    max-width: 800px;
    width: 100%;
}
.loaderBg{
    background-color: #f1f1f1;
    min-height: 300px;
    padding-top: 30px;
    padding-left: 30px;
}
.minWidthCarosel{
    max-width: 800px;
    margin: auto;
}
.w_1000{
    width: 500px;
}
.mageHeaderss{
    background-color: #CF5271;
    border-radius: 10px;
    padding: 20px;
    display: flex;
}
.modelling{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.first_side{
    background-color: rgb(52,145,232,.1);
    height: 90vh;
    display: flex;
}
.first_sideContent{
    max-width: 450px;
    margin: 12vh 0 0 auto;
    height: 425px;
    background-color: #fff;
    width: 100%;
    position: relative;
    padding: 50px;
}
.first_side2{
    height: 90vh;
    display: flex;
    background-color: rgb(207,12,54,.08);
}
.second_sideContent{
    max-width: 450px;
    margin: 12vh auto 0 0;
    height: 425px;
    width: 100%;
    position: relative;
}
.second_sideContent img{
    width: 100%;
    height: 100%;
}
.ModelsHeaderCont_ents{
    font-size: 50px;
    font-weight: 800;
    line-height: 1;
    margin-top: 15%;
    color: black;
}
.modeling_BTN{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: rgb(52,145,232,.9);
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 175px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.modeling_BTN2{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #3491E8;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 40px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.modeling_BTN:hover{
    color: #ffffff;
}


.modeling_BTN3{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: rgb(207,12,54,.9);
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 175px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.modeling_BTN5{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #CF0C36;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 40px;
    width: 100%;
    text-align: center;
    padding: 14px ;
}
.cart_container{
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-gap: 20px;
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
}
.cart_body{
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 0 30px 0;
}
.cart_body2{
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 0 0 0;
}
.cart_divider{
    border-bottom: 1px solid #eceefc;
    padding: 15px;
}
.cart_dividers{
    display: grid;
    grid-template-columns: 1fr 4fr;
}
.cart_dividersheck{
    display: grid;
    grid-template-columns: 2fr 5fr;
}
.cart_headerTit{
    font-size: 19px;
    font-weight: 700;
    padding: 0 15px 5px 15px;
    border-bottom: 2px solid #eceefc;
}
.xs .cart_divider button{
    border-radius: 5px;
    background-color: #3491E8;
    padding: 0 5px;
    color: #fff;
    width: 25px;
    display: block;
    text-align: center;
    border: none;
}
.cart_dividers img,.cart_dividersheck img{
    border-radius: 5px;
    max-height: 170px;
    color: #eceefc;
}
.cart_divider input{
    max-width: 500px;
    width: 100%;
    border: 2px solid #ECEEFC;
    padding: 8px 10px;
    border-radius: 7px;
}
.cart_divider input.error{
    border: 2px solid #CF5271;
}
.submitHandlerBtn{
    background-color: #3491E8;
    color: #fff;
    padding: 7px 20px;
    max-width: 150px;
    text-align: center;
    display: inline-block;
    width: 100%;
    border: none;
    border-radius: 5px;
}
.ItemsDetail{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 40px;
}
.divItemsOption{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 30px 0;
}
.divItemsOption div{
    padding: 10px;
}
.divItemsOption img{
    border-radius: 4px;
}
.divItemsOption img:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.detailRightN{
    padding-left: 100px;
}
.detailCartAdd{
    border-radius: 40px;
    display: flex;
    background-color: #f1f1f1;
    max-width: 300px;
}
.detailCartAdd span{
    font-size: 26px;
    padding: 8px 30px 14px 30px;
    display: inline-block;
}
.detailCartAdd samp{
    font-size: 22px;
    padding: 14px 10px 6px 10px;
    display: inline-block;
}
.AddToCartDetailsBtn{
    padding: 13px 20px;
    border: none;
    border-radius: 30px;
    max-width: 180px;
    width: 100%;
    text-align: center;
    background-color: #3491E8;
    color: #fff;
}


.loginFormBg{
    background-color: #EFF0F4;
    height: 94vh;
    padding: 0 10px;
}
.loginFormBg2{
    background-color: #EFF0F4;
    padding: 0 20px;
}

.loginContainerBox3{
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    margin: auto;
    padding: 40px;
    border-radius: 7px;
}
.paddForm{
    padding: 90px 0;
}
.loginContainerBox3 input,
.registerContent input{
    margin: 15px 0;
    padding: 9px 10px;
    display: block;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #ccc7c7;
}
.loginContainerBox3 input.xb,
.registerContent input.xb{
    border: none;
    margin: 0;

}
.registerContent .signUp_address,
.registerContent .signUp_address2{
    margin: 15px 0;
}
.registerContent .signUp_address .xcv,
.registerContent .signUp_address2 .xcv{
    border-radius: 4px 0 0 4px;
}
.registerContent .signUp_address input,
.registerContent .signUp_address2 input{
    margin: 0;
}
.registerContent .signUp_address .xcv2,
.registerContent .signUp_address2 .xcv2{
    border-radius: 0 4px 4px 0 ;
}
.signUp_address{
    display: grid;
    grid-template-columns: 5fr 1fr;
}
.signUp_address2{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.passwordFormContainer{
    display: flex;
    margin: 15px 0;
    /* padding: 9px 10px; */
    border-radius: 4px;
    border: 1px solid #ccc7c7;
}
.sight{
    padding-top: 9px;
    padding-right: 5px;
}
.loginContainerBox3 button{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 9px;
}
.signUpFormContainer button{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    max-width: 200px;
    border: none;
    padding: 9px;
    width: 100%;
}
.signUpFormContainer{
    margin: 10px auto;
    max-width: 1200px;
    background-color: #fff;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 5px;
}

.signUpForms{
    padding: 0 100px 60px 60px;
}
.classics img{
    border-radius: 5px;
    /* height: 100%; */
}
.borrad{
    border: 0.5px solid #f1f1f1;
}
.myMensory img{
    padding: 5px;
    border-radius: 5px;
}
.kidsProfile img{
    border-radius: 10px;
}
.kidsProfile{
    margin: 5px;
}
.center_imager{
    display: block;
    
    object-fit: cover;
    object-position: center;
    max-width: 100%;
}
.get_featured_mag_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    row-gap: 40px;
    grid-column-gap: 40px;
    column-gap: 40px;
    padding-top: 40px;
}
.modalHeader{
    border: none;
}
.get_featured_mag_container img{
    max-width: 100%;
}
/* .modal-content {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 18px;
    height: 533px;
} */

.mdalcontent{
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 18px;
    height: 533px;
}
.fade.modal.show{
    padding: 0;
}
.modalImgContainer{
    display: flex;
    border-radius: 10px 0 0 10px;
    align-items: center;
    background: rgb(255,255,255,.5);
}
.cancelImg{
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 100;
}
.modalImgContainer img{
    width: 100%;
    border-radius: 10px 0 0 10px;
}
.modalProfileInfo{
    background-color: #fff;
    border-radius: 0 10px 10px 0;
}
.profContentt{
    border-radius: 12px;
    background-color: #f1f1f1;
    margin: 5px;
    
}
.padPf{
    padding: 10px 20px;
}
.appearanceContainer{
    padding: 20px;
}
.div2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 15px;
    row-gap: 15px;
}
.socialMdea{
    display: flex;
    max-width: 230px;
    justify-content: space-between;
}
.shop_item img{
    height: 260px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 4px;
}
.myToast{
    position: absolute;
    z-index: 1000000000000;
    background-color: #3491E8;
}
.maxWidth{
    max-width: 200px;
    margin: auto;
    
    
}
.maxWidth .fade.toast{
    background-color: transparent !important;
    border: none;
    box-shadow: none;
}
.Toaster{
    background-color: rgb(25,135,84,.5);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
}
.pt{
    padding-top: 2px;
}
input.xdForm,
.xdForm.passwordFormContainer{
    border: 1px solid #CF0C36 !important;
}
input::placeholder {
    color: #757680;
}
.absoluteAdd{
    position: absolute;
    right: 1px;
    font-size: 25px;
    top: -7px;
}
.pr_25{
    padding-right: 25px;
}
.cencelBtnIcon{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
input.errr{
    border: 1.5 solid #DC3545 !important;
}
.content_Tickets_container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(25rem, 100%), 1fr));
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    column-gap: 30px;
    background: transparent;
}
.tickConatainer{
    background-color: #fff;
}
.tickConatainer .ticketImgBg{
    max-height: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
}
.tickets_detailerContainer{
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100%;
    position: relative;
    z-index: 10;
}
.qrcodeImg img{
    max-width: 120px;
}
.text_TickestInfo{
    border-right: 2px dashed black;
    padding: 15px;
}
.extraTicket{
    min-height: 100px;
}
.form-row.pt_2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    column-gap: 20px;
}
.ImgMagrequest{
    text-align: center;
}
.emptyImg{
    max-width: 500px;
}
.emptyImg img{
    max-width: 100%;
}
.shop .shop_item img{
    max-width: 100%;
}
.disp-Grid{
    display: grid;
}
.terms_of_use_container{
    position: relative;
    padding-top: 40px;
}
.red_bg_terms{
    background-color: #CF0C36;
    position: absolute;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100%;
}
.terms_of_use_content{
    max-width: 80%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 10;
    background-color: #fff;
    padding: 40px 30px;
}
.ckecb0x span, .ckecb0x input{
    margin: auto 0;
}
@media (max-width:900px) {
    .shop{
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .shop .shop_item img{
        height: 240px;
        display: block;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
    }
    .magazin_covers{
        grid-gap: 20px;
    }
    .diveid img{
        max-width: 100%;
        border-radius: 4px;
    }
    .signUpForms{
        padding: 20px 60px 20px 20px;
    }
    .detailRightN{
        padding-left: 20px;
    }
}
@media (max-width:860px) {
    .diveid{
        bottom: -80px;
        padding: 20px;
    }
    .eventGridContainer{
        grid-gap: 20px;
    }
    
    .eventGridContainer .p-3{
        padding: 12px !important;
    }
    .cart_container{
        grid-template-columns: 3fr 2fr;
    }
    .get_featured_mag_container{
        grid-template-columns: 1fr;
        
    }
    
}
@media (max-width:800px) {
    .signUpForms{
        padding: 20px 40px 20px 0;
    }
}
@media (max-width:700px) {
    .signUpFormContainer{
        padding: 15px;
        grid-template-columns: 1fr;
        max-width: 400px;
        margin: auto;
    }
    .ItemsDetail{
        grid-template-columns: 1fr ;
    }
    .detailRightN{
        padding-left: 0;
        padding-top: 30px;
    }
    .classics{
        display: none;
    }
    .loginFormBg2{
        padding: 0 10px;
    }
    .signUpForms{
        padding: 20px 0;
        
    }
}
@media (max-width:650px) {
    .eventGridContainer{
        grid-template-columns: 1fr 1fr;
    }
    .modelling{
        grid-template-columns: 1fr;
    }
    .second_side{
        display: none;
    }
    .first_sideContent{
        max-width: 100%;
        margin: 30px 0 0 0;
        
    }
    .ClassHeader{
        font-size: 28px;
    }
    .classesDescHeader{
        font-size: 23px;
    }
    .classDivider{
        grid-template-columns: 1fr;
    }
    
    .diveid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        position: static;
        margin-bottom: -200px;
        padding: 0;
    }
    .font_40{
        font-size: 20px;
    }
    .aboutLander2 .font_20{
        font-size: 14px;
    }
    .shop{
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    .shop .shop_item img{
        height: 150px;
        display: block;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
    }
    .magazin_covers{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .magazin_covers .p-3{
        padding: 10px !important;
    }
    .cart_container{
        grid-template-columns: 1fr;
        padding: 0 10px;
    }

}

@media (max-width:600px) {
    .homeBased .carousel-control-prev,
    .homeBased .carousel-control-next {
        height: 30px;
        width: 30px;
        border-radius: 25px;
    }
    .haefa .carousel-control-prev-icon,
    .carousel-control-next-icon{
        width: 1rem;     
    }


    .receipts *{
        font-size: 12px;
    }
    .receipt_services_header{
        font-size: 18px;
    }
    .receipt_header{
        grid-template-columns: 1fr;
    }
    .receipts .logoContentContaIner{
        position: absolute;
        top: 0;
        right: 0;
    }
    .left_auto.receipt_services_header{
        margin: 0;
    }
    .homeBased .carousel-control-prev-icon,
    .carousel-control-next-icon{
        width: 1rem; 
    }
    .model_class_CONtainer{
        grid-template-columns: 1fr;
    }
    .font_32{
        font-size: 25px;
    }
    .standard_width.xr{
        padding-top: 50px;
    }
    .flex.xbfd{
        display: block;
    }
    .xbfd .ml_2{
        margin: 35px 0 0 0;
    }
    /* .modal-content {
        grid-template-columns: 1fr;
        height: inherit;
    } */
    .mdalcontent{
        grid-template-columns: 1fr;
        height: inherit;
    }
    .modalImgContainer img{
        border-radius: 10px 10px 0 0;
    }
    .modalProfileInfo{
        background-color: #fff;
        border-radius: 0 0 10px 10px;
    }
    .modalImgContainer{
        border-radius: 10px 10px 0 0;
    }
}
@media (max-width:550px) {
    .paddForm{
        padding: 40px 0;
    }
    .loginContainerBox3{
        padding: 25px;
    }
    .ModelsHeaderCont_ents{
        font-size: 26px;
    }
    .aboutLander2{
        height: 480px;
        grid-template-columns: 1fr;
        padding: 35px;
        grid-gap: 0;
    }
    .aboutLander2 img{
        height: 300px;
        display: block;
    
        object-fit: cover;
        object-position: center;
        max-width: 100%;
    }
    .font_12{
        font-size: 11px;
    }
    .font_15{
        font-size: 12px;
    }
    .mageHeader .font_20{
        font-size: 15px;
    }
    .upcoming_mag_Btn2{
        max-width: 100px;
        padding: 7px 10px;
        font-size: 12px;
    }
}

@media (max-width:500px) {
    .cart_dividers .font_18{
        font-size: 13px;
    }
    .cart_dividers .font_14{
        font-size: 11px;
    }
    .cart_dividers .font_20{
        font-size: 14px;
    }
    .cart_dividers .mt_1{
        margin-top: 2px;
    }
    .cart_divider .pt_2{
        padding-top: 10px;
    }
    .cart_divider .text-danger{
        font-size: 12px;
    }
    .font_24{
        font-size: 18px;
    }
    .border_filter .borderRight{
        font-size: 11px;
    }
    .border_filter .borderRight.py_2{
        padding: 15px 0;
    }
    .border_filter{
        margin-top: 14px;
    }
    .shop{
        grid-gap: 10px;
    }
    .shop_description .font_16{
        font-size: 12px;
    }
    .shop_description .font_12{
        font-size: 11px;
    }
    .shop_description{
        padding: 10px
    }
    .magazin_covers{
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }
    .p-4.mainsRecpt{
        padding: 20px 0 !important;
    }
    .receipt_content{
        padding: 30px 0;
    }
}
@media (max-width:450px) {
    .terms_of_use_content{
        max-width: 95%;
        padding: 25px 15px;
    }
    .terms_of_use_container .pb_4{
        padding-bottom: 20px;
    }
    .font_16{
        font-size: 13px;
    }
    .detailCartAdd{
        max-width: 150px;
    }
    .detailCartAdd span{
        font-size: 26px;
        padding: 8px 20px 10px 20px;
    }
    .detailCartAdd samp{
        font-size: 17px;
        padding: 19px 10px 6px 10px;
    }
    .pl_2.msw{
        padding-top: 10px;
    }
    .AddToCartDetailsBtn{
        padding: 10px 20px;
        max-width: 150px;
    }
    
    .font_28{
        font-size: 22px;
    }
    .aboutLander2fx .mt_7{
        margin-top: 30px;
    }
    .mageHeader .font_20{
        font-size: 12px;
    }
    .eventGridContainer{
        grid-template-columns: 1fr;
    }
    .upcoming_mag_Btn2{
        max-width: 80px;
        padding: 6px 10px;
        font-size: 11px;
    }
    .diveid{
        margin-bottom: -160px;
        padding: 0;
    }
    .classLanding_page{
        padding: 20px;
    }
    .standard_width.xr{
        padding-top: 80px;
    }
}
@media (max-width:400px) {
    .aboutLander2{
        padding: 15px;
    }
    .font_20{
        font-size: 16px;
    }
    .border_filter .borderRight{
        font-size: 10px;
    }
    .border_filter .borderRight.py_2{
        padding: 10px 0;
    }
    .upcoming_mag_Btn2{
        min-width: 80px;
        padding: 6px;
        font-size: 10.5px;
    }
}
.aboutLander{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, rgba(0,0,0,.4), rgb(52,145,232,.4)),url(/static/media/lkimags.a8bc3713.jpeg);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding: 35px;
}
.aboutSign_upBtn{
    color: #fff;
    background-color: #3491E8;
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    padding: 8px 20px;
}
.aboutSign_upBtn:hover{
    color: #fff;
}
.enrol_mag_container{
    margin-left: auto;
    background-color: rgb(52,145,232,.5);
    border-radius: 10px;
    padding: 30px;
    max-width: 350px;
    width: 100%;
}
.upcoming_mag_Btn{
    color: #fff;
    background-color: rgb(207,12,54,.9);
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    padding: 8px 20px;
}
.upcoming_mag_Btn:hover{
    color: #fff;
}
.ulMag li{
    margin-left: -12px;
}
.xdv .active, .red{
    color: #BD123D;
}
.bg_red{
    background-color: #BD123D;
}
.standardTwo{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}
.aboutLandpg img{
    width: 100%;
    max-height: 600px;
    border-radius: 10px;
}
@media (max-width:700px) {
    .standardTwo{
        grid-gap: 20px;
    }
}
@media (max-width:600px) {
    .standardTwo{
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
    .standardTwo p.font_24{
        font-size: 19px;
    }
    .standardTwo p.font_20{
        font-size: 15px;
    }
}






















.bg_footer{
    background-color: #EFF1FD;
    margin-top: 40px;
    /* border-top: 1px solid #f1f1f1; */
}
.footer_container{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 30px;
    padding: 60px 0 40px 0;
}
.social_media_logo a{
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    margin-right: 20px;
    padding-top: 6px;
}
.social_media_logo{
    padding-top: 30px;
}
.otherRoutes{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid: 30px;
    padding-top: 100px;
}
.otherRoutes span{
    padding-top: 10px;
    font-weight: 500;
}
.borderTop{
    border-top: 1px solid #c0bcbc;
}

@media (max-width: 850px) {
    .footer span,.footer p span,.footer a{
        font-size: 14px;
    }
    .social_media_logo a{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        padding-top: 6px;
    }
}
@media (max-width: 650px) {
    .footer_container{
        grid-template-columns: 1fr;
        grid-gap: 30px;
        /* padding: 60px 0 40px 0; */
    }
    .otherRoutes{
        padding-top: 0;
    }
}
@media (max-width: 450px) {
    .otherRoutes{
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
    
    
    .borderTop.text-muted{
        font-size: 12px;
        text-align: center;
    }
}


































.navSideBar{
    position: fixed;
    width: 100px;
    height: 100%;
    /* background-color: aqua;     */
}
.classessContent_subs{
    max-height: 370px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}
.classessContent_subs::-webkit-scrollbar {
    display: none;
}
.sideBarIconcontainer{
    position: absolute;
    height: 500px;
    width: 70px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #CF5271;
    border-radius: 50px;
}
/* .bg_blur{
    background-color: #f1f1f1;
    min-height: 100vh;
} */
.mainContent{
    background-color: #fff;
    margin: 0 10px 10px 50px;
    border-radius: 15px;
    min-height: 97vh;
    padding-left: 50px;
}

.iconsConts{
    padding-top: 15px;
    text-align: center;
    margin-top: 25px;
}
.iconsConts div{
    margin-bottom: 25px;
}
.dashNavs{
    display: flex;
    padding: 15px 35px 15px 15px;
    border-radius: 10px;
    margin-right: 10px;
}
.navbarStickyTop{
    border-radius: 10px;
}
.iconser{
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.possitionIconizer{
    padding: 10px 0 0 10px ;
}
.dashNavLinks{
    padding: 14px 10px 0 10px ;
}
.mainContentDashboard{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 40px;
    padding: 20px 50px 20px 20px;
}
.plansContainerInfo{
    padding: 15px;
    border-radius: 20px;
}
.spand{
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 2px;
}
.spand div{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #ddd9d9;
}
.subscribe_btn{
    background-color: #f1f1f1;
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}

.subscribe_btns{
    background-color: rgb(207,82,113,.1);
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}
.subscribe_btns.sbs{
    background-color: transparent;
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}
.recolor{
    color: rgb(207,82,113);
}

.plansContainerInfos{
    padding: 5px;
    border-radius: 20px;
}
.plansContainerInfos .p-2{
    border-radius: 15px;
    padding: 9px;
    /* border: 1px solid #f1f1f1; */
}
.bootDesign{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 40px;
    padding: 10px 50px 20px 20px;
}
.bootContent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.active .plansContainerInfo .spand div{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #3491E8;
}
.active .subscribe_btn{
    background-color: rgb(52,145,232,.2);
}
.active .subscribe_btn .text-muted{
    color: rgb(52,145,232) !important;
}
.magazineDashboardContainer{
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-gap: 15px;
    margin-top: 10px;
    padding: 5px;
}
.ClassicItemsSales img{
    height: 118px;
    width: 100%;
    border-radius: 10px;
}
.eventDashboardContainer{
    display: grid;
    grid-template-columns: 2fr 8fr;
    grid-gap: 10px;
    margin-top: 10px;
    /* padding: 5px; */
}


.mainContentDashboardsubs{
    padding: 20px 50px 20px 20px;
}
.mainContentDashboardsubsContainer{
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-gap: 40px;
}

.profileImageContainer img{
    border-radius: 10px;
}
.profileImageContainer{
    position: relative;
}
.padProfilexds{
    padding-top: 20px;
}
.profileSkills div{
    font-size: 14px;
}
.profilePhotosContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 20px;
    column-gap: 20px;
}
.profileVideoContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}

.profileRemarkContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}
.PVContainer{
    border-radius: 10px;
    padding: 20px;
}
.DashboardTicketsContainer{
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-gap: 30px;
}
.tickets{
    padding-left: 10px;
    border-left: 5px solid #CF5271;
    margin-bottom: 15px;
}
.flex.xdv{
    justify-content: space-between;
    max-width: 700px;
    width: 100%;
    padding-top: 15px;
}
.settignsContent{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}
.setPcont{
    margin: 10px 0 20px 0;
    border-radius: 20px;
    padding: 30px 40px;
}
.setPcont input{
    width: 100%;
    border: 1px solid #f1f1f1;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 14px;
}
.grided{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.grided3{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.padSociaDisplay{
    padding: 4px 20px;
}
.dashNavs samp,.cativ{
    display: none;
}
.ClassicItemsSales.sm{
    display: none;
}
.profileEditContainer{
    max-width: 600px;
    width: 100%;
}
.profileEditContainer div{
    padding-bottom: 20px;
}
.profileEditContainerXr input[type=text],
.profileEditContainerXr input[type=number],
.profileEditContainerXr select,
.profileEditContainer input[type=text],
.profileEditContainer input[type=number],
.profileEditContainer select,
.grided33 textarea{
    border: none;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px;
    width: 100%;
    outline: none;
    resize: none;
}
.profileEditContainer input[type=text]:focus,
.profileEditContainer select:focus,
.grided33 textarea:focus{
    border-bottom: 1px solid #3491E8;
}
.inputFormBtn{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #3491E8;
    padding: 6px 30px;
    border-radius: 10px;
    max-width: 400px;
}
.tenderProfileImg{
    max-width: 300px;
}
.delEditImg button{
    color: #fff;
    font-weight: 600;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 10px 5px;
}
.del_img_mod{
    background-color: rgb(255,255,255,.5);
    border-radius: 15px;
    padding: 5px;
}
.bgWhitee{
    background-color: #fff;
    border-radius: 12px;
    padding: 7px;
}
.del_img_mod button{
    background-color: #CF5271;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 7px;
    padding: 7px;
    width: 100%;
}
.modal-content {
    display: block !important;
    height: initial !important;
}
.youtubeInputContainer{
    display: flex;
    max-width: 500px;
    border-radius: 60px;
    height: 50px;
    padding: 5px;
}
.youtubeInputContainer input{
    border: none !important;
    max-width: 300px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
    border-radius: 50px;
}
.youtubeInputContainer button{
    margin-left: auto;
    border-radius: 50px;
    background-color: #3491E8;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    width: 120px;
}
.removeVideo{
    color: #fff;   
    padding: 5px 15px;
    border-radius: 0 30px 30px 0;
    margin-top: 0;
    font-size: 12px;
}
@media (max-width:900px) {
    .DashboardTicketsContainer{
        grid-template-columns: 1fr;
    }
    .zaTickets .zaTickets{
        display: none;
    }
    .mainContentDashboard{
        grid-template-columns: 2fr 3fr;
    }
    .font24{
        font-size: 12px;
    }
    .subscribe_btn{
        margin-top: 10px;
    }
    .subscribe_btn .font_14{
        font-size: 11px;
    }
    .bootContent{
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }
    
    .ClassicItemsSales{
        display: none;
    }
    .ClassicItemsSales.sm{
        display: block;
    }
    .bootDesign{
        grid-template-columns:1fr;
        padding: 10px 20px 20px 20px;
    }
    .mainContentDashboardsubsContainer{
        grid-template-columns: 2fr 3fr;
    }
    .profilePhotosContainer{
        grid-template-columns: 1fr 1fr 1fr ;
        grid-row-gap: 60px;
        row-gap: 60px;
        grid-column-gap: 10px;
        column-gap: 10px;
    }
    .mainContentDashboardsubs{
        padding: 13px;
    }
    .grided3{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width:700px) {
    .mainContentDashboard{
        grid-template-columns: 1fr;
        padding: 20px;
    }
    .mainContentDashboardsubsContainer{
        grid-template-columns: 1fr;
    }
    
    .profileImageContainer img{
        max-height: 350px;
    }
    .profileVideoContainer{
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    .settignsContent img{
        display: none;
    }
    .settignsContent{
        grid-template-columns: 1fr;
    }
}
@media (max-width:650px) {
    .dashNavLinks,.px_2 .iconser
    ,.px_1 .iconser,.navSideBar{
        display: none;
    }
    .mainContent{
        background-color: #fff;
        margin: 0 10px 10px 10px;
        padding-left: 0;
    }
    .dashNavs{
        padding: 15px 15px 15px 15px;
        margin-right: 0;
    }
    .dashNavs samp,.cativ{
        display: block;
    }
    .profilePhotosContainer{
        grid-template-columns: 1fr 1fr ;
    }
}
@media (max-width:600px) {
    .profileRemarkContainer{
        grid-template-columns: 1fr;
    }
}
@media (max-width:500px) {
    .settingsContainer .pointer.pr_1{
        font-size: 12px;
    }
    .setPcont{
        margin: 10px 0 20px 0;
        border-radius: 20px;
        padding: 30px 15px;
    }
    .grided{
        grid-template-columns: 1fr;
    }
    .grided3{
        grid-template-columns: 1fr;
    }
}
@media (max-width:450px) {
    .bootContent{
        grid-template-columns: 1fr;
    }
    .font_38{
        font-size: 20px;
    }
    .profilePhotosContainer{
        grid-template-columns: 1fr ;
    }
    .profileVideoContainer{
        grid-template-columns: 1fr;
    }
    .settingsContainer .pointer.pr_1{
        font-size: 10px;
        padding-right: 4px;
    }
}





















