.aboutLander{
    width: 100%;
    min-height: 500px;
    align-items: center;
    background: linear-gradient(to right, rgba(0,0,0,.4), rgb(52,145,232,.4)),url(../Css/Images/lkimags.jpeg);
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 1 !important;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding: 35px;
}
.aboutSign_upBtn{
    color: #fff;
    background-color: #3491E8;
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    padding: 8px 20px;
}
.aboutSign_upBtn:hover{
    color: #fff;
}
.enrol_mag_container{
    margin-left: auto;
    background-color: rgb(52,145,232,.5);
    border-radius: 10px;
    padding: 30px;
    max-width: 350px;
    width: 100%;
}
.upcoming_mag_Btn{
    color: #fff;
    background-color: rgb(207,12,54,.9);
    display: inline-block;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    padding: 8px 20px;
}
.upcoming_mag_Btn:hover{
    color: #fff;
}
.ulMag li{
    margin-left: -12px;
}
.xdv .active, .red{
    color: #BD123D;
}
.bg_red{
    background-color: #BD123D;
}
.standardTwo{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}
.aboutLandpg img{
    width: 100%;
    max-height: 600px;
    border-radius: 10px;
}
@media (max-width:700px) {
    .standardTwo{
        grid-gap: 20px;
    }
}
@media (max-width:600px) {
    .standardTwo{
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
    .standardTwo p.font_24{
        font-size: 19px;
    }
    .standardTwo p.font_20{
        font-size: 15px;
    }
}





















